
/*
 * Globals
 */

/* Links */
a,
a:focus,
a:hover {
  color: #fff;
}

/* Custom default button */
.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus {
  color: #333;
  text-shadow: none; /* Prevent inheritance from `body` */
  background-color: #5e2c0f;
  border: none;
}

.btn:focus {
  border: none;
}


/*
 * Base structure
 */

html,
body {
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  text-align: center!important;
}

body {
  display: -ms-flexbox;
  display: flex;
  color: #fff;
  background: black;
}

.cover-container {
  max-width: 42em;
}

/*
 * Header
 */
.masthead {
  margin-bottom: 2rem;
}

.masthead-brand {
  margin-bottom: 0;
}

.sites-logo {
  vertical-align: middle;
    border-style: none;
    mix-blend-mode: normal;
    width: auto;
    max-height: 60px;
    margin: 1rem;
}

.nav-masthead .nav-link {
  padding: .25rem 0;
  font-weight: 700;
  color: rgba(255, 255, 255, .5);
  background-color: transparent;
  border-bottom: .25rem solid transparent;
}

.nav-masthead .nav-link:hover,
.nav-masthead .nav-link:focus {
  border-bottom-color: rgba(255, 255, 255, .25);
}

.nav-masthead .nav-link + .nav-link {
  margin-left: 1rem;
}

.nav-masthead .active {
  color: #fff;
  border-bottom-color: #fff;
}

h1 {
  font-family: "Work Sans", sans-serif;
  font-weight: 800;
  font-size: 5em;
  line-height: 1.3em;
  margin-left: auto;
  margin-right: auto;
}

h3 {
  /* font-family: "Work Sans", sans-serif; */
  font-weight: 600;
  font-size: 1em;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: .2rem;
  line-height: 2em;
  color: #ffd200
}

.sites {
    border-radius: 1.3rem;
    width: 80vw;
    padding: 0.1em;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    background: rgba(255, 255, 255, 0.09);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(1.1px);
    -webkit-backdrop-filter: blur(1.1px);
    border: 1px solid rgba(255, 255, 255, 0.21);
    mix-blend-mode: hard-light;
}


.btn {
  background: rgb(255,188,0);
  background: linear-gradient(348deg, rgba(255,188,0,1) 44%, rgba(255,231,0,1) 85%);
  border: none;
}

.btn:hover {
  border: none;
  background: rgb(255,188,0);
  background: linear-gradient(348deg, rgba(255,188,0,1) 44%, rgba(255,231,0,1) 85%);
}

/* Style the rainbow text element. */
.rainbow-text {
  /* Create a conic gradient. */
  /* Double percentages to avoid blur (#000 10%, #fff 10%, #fff 20%, ...). */
  background: rgb(252,184,88);
background: linear-gradient(360deg, rgba(252,184,88,1) 25%, rgba(255,231,0,1) 47%, rgba(255,253,117,1) 74%);
  /* Set thee background size and repeat properties. */
  
  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  
  /* Animate the text when loading the element. */
  /* This animates it on page load and when hovering out. */
}

/* Add animation on hover. */
.rainbow-text:hover {
  animation: rainbow-text-animation 0.5s ease forwards;
}

#videoBG {
  width: auto;
  height: 100%;
  position: fixed;
  z-index: -2;
  overflow: hidden;
  top: 0;
  left: 0;
}

.videoImg {
  display: none;
  border-radius: 10px;
}

.transBg {
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 5%, rgba(0,0,0,0.5802696078431373) 18%, rgba(0,0,0,0.2189250700280112) 83%, rgba(0,0,0,1) 97%, rgba(0,0,0,1) 100%);
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
}

.blur {
  filter: blur(74px);
    width: 100px;
    height: 157px;
    background-color: #00000061;
    position: absolute;
    width: 60vw;
    z-index: -1;
    top: 15px;
    margin-left: 1vw;
    mix-blend-mode: hard-light;
}

/* form css */

.contactBg {
  background: url("./images/istockphoto-1403848173-612x612.jpg") repeat;
  z-index: -2;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

form {
  color: #282828;
}

input:-internal-autofill-selected {
  background-color: rgb(0, 0, 0, 0.8)!important;
  background-clip: border-box;
  border: 1px solid rgb(0 0 0 / 80%)!important;
  color: #ffffff!important;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #b5b5b5;
  background-clip: border-box;
  border: 1px solid rgb(0 0 0 / 13%);
  border-radius: 0.25rem; 
  font-family: "Work Sans", sans-serif;
  font-weight: 600;
  font-size: 1em;
  text-align: left;
  max-width: 900px;
}

.card-header {
  font-size: 1.4rem;
}

.trans {
  background-color: rgb(0 0 0 / 82%);
  background-clip: border-box;
  border: 1px solid rgb(0 0 0 / 25%);
}


.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000000;
  background-color: #b1b1b1;
  background-clip: padding-box;
  border: 1px solid #a1a1a1;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-control {
  background-color: rgb(0, 0, 0, 0.8);
  background-clip: border-box;
  border: 1px solid rgb(0 0 0 / 80%);
  color:#ffffff;
}

.form-control:focus {
  border-color: #08c4ca!important;
  background-color: rgb(0, 0, 0, 0.8);
  background-clip: border-box;
  border: 1px solid rgb(0 0 0 / 80%);
  color:#ffffff;
  box-shadow: 0 0 0 0.2rem rgba(9, 231, 247, 0.25)!important;
}

.error{
  border-color: #ff0000!important;
  background-color: rgb(0, 0, 0, 0.8);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(255 0 0 / 25%)!important;
}


.error:focus{
  border-color: #ff0000!important;
  background-color: rgb(0, 0, 0, 0.8);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(255 0 0 / 25%)!important;
}

.form-group label {
  color:#ffffff;
}

.error-message {
  color: #a1a1a1;
  font-size: 0.8rem;
  margin: 0.6em;
}

.contact .btn {
  color: #633807;
}

/* X-Large devices (large desktops, less than 1400px) */
@media (min-width: 1199.99px) {
  H1 {
    font-size: 2.7em;
  }
  .sites {
    width: 60vw;
  }
  #videoBG {
    width: 100%;
    height: 100%;
    min-height: 1044px;
    min-width: 1920px;
    position: fixed;
    z-index: -2;
    overflow: hidden;
    top: 0;
    left: 0;
  }
}

/* Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {
  H1 {
    font-size: 2.5em;
  }
  .sites {
    width: 80vw;
  }
  #videoBG {
    width: 100%;
    height: auto;
    position: fixed;
    z-index: -1;
    overflow: hidden;
    top: 120px;
    left: 0;
  }

  video {
    display: none;
  }

  .videoImg {
    display: block;
    width: 80%;
    height: auto;
    position: fixed;
    z-index: -1;
    overflow: hidden;
    top: 120px;
    left: 10%;
    right: 10%;
    border-radius: 1rem;
  }

}

/* Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {
  H1 {
    font-size: 2.5em;
  }
  H3 {
    font-size: 1.2em;
    letter-spacing: none;
  }
  #videoBG {
    top: 200px;
  }
  .sites {
    width: 80vw;
  }
  #videoBG {
    width: 100%;
    height: auto;
    position: fixed;
    z-index: -1;
    overflow: hidden;
    top: 120px;
    left: 0;
  }

  video {
    display: none;
  }

  .videoImg {
    display: block;
    width: 80%;
    height: auto;
    position: fixed;
    z-index: -1;
    overflow: hidden;
    top: 120px;
    left: 10%;
    right: 10%;
    border-radius: 1rem;
  }
}

/* Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) { 
  H1 {
    font-size: 1.3em;
  }
  H3 {
    font-size: 2em;
    font-weight: 200;
    letter-spacing: 0em;
      /* line-height: 2em; */
  }
  .sites {
    width: 80vw;
    top: 15vh;
    background: none;
    border: none;
  }
  #videoBG {
    width: auto;
    height: 100%;
    position: fixed;
    z-index: -1;
    overflow: hidden;
    top: 60px;
    left: 0;
  }

  video {
    display: none;
  }

  
  .videoImg {
    display: block;
    width: 80%;
    height: auto;
    position: fixed;
    z-index: -1;
    overflow: hidden;
    top: 120px;
    left: 10%;
    right: 10%;
    border-radius: 1rem;
  }

  .transBg {
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 5%, rgba(0,0,0,0.5802696078431373) 18%, rgba(0,0,0,0.9) 83%, rgba(0,0,0,1) 100%);
  }

}

/* X-Small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) { 
  H1 {
    font-size: 1.4em;
  }
  H3 {
    font-size: 2em;
    font-weight: 200;
    letter-spacing: 0em;
  }
  .sites {
    width: 80vw;
    top: 15vh;
    background: none;
    border: none;
  }
  #videoBG {
    width: auto;
    height: 100%;
    position: fixed;
    z-index: -1;
    overflow: hidden;
    top: 0;
    left: 0;
  }

  video {
    display: none;
  }

  .videoImg {
    display: block;
    width: 80%;
    height: auto;
    position: fixed;
    z-index: -1;
    overflow: hidden;
    top: 120px;
    left: 10%;
    right: 10%;
  }

  .transBg {
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 5%, rgba(0,0,0,0.5802696078431373) 18%, rgba(0,0,0,0.9) 83%, rgba(0,0,0,1) 100%);
   }
   .masthead-brand {
    display: none;
   }

   .mastfoot {
    visibility: hidden;
   }
   
 }

@media (min-width: 48em) {
  .masthead-brand {
    float: left;
  }
  .nav-masthead {
    float: right;
  }
}
